import BaseService from './base-service';
import { camelCase, cloneDeep, get, isEmpty, isEqual, mapKeys, omit, pick } from 'lodash';
import { gte } from 'semver';

// constants
import { API_BASE_PATH, ASSET_DOWNLOAD_URL, STUDIO_EXPRESS_BASE_PATHLET } from 'libs/utils/constants';

/**
 * @typedef {'approved'|'archived'|'answered'|'pending'|'highlighted'} QnaQuestionStatus
 *     when a quesiton is created from app it's status is `pending` (has to be approved)
 *     when it's approved or created from backstage then it's `approved`, then it's in the "Discussed" bucket
 *     question can be set to `archived` when not being concerned anymore
 *     moderator can push the question to screen, then it's status is `highlighted`
 *     after that the question becomes 'answered'. a question can also be moved manually to 'answered' without ever being highlighted
 */

/**
 * @typedef {object} QnaQuestion Live Stream Q&A Question entity
 * @property {string} id
 * @property {QnaQuestionStatus} status
 * @property {string} pid participant's id
 * @property {string} participant name of the participnat
 * @property {boolean} [anonymous] question can be anonymous, then the participant name is not displayed
 * @property {string} question the text of the question
 * @property {number} sent unix timestamp, when the question was sent
 * @property {boolean} highlighted whether the question is pushed to the screen, currently discussed
 * @property {boolean} [discussed] true if the question was already discussed
 * @property {QnaQuestionStatus} [prev_status]
 */

/**
 * @typedef {object} Poll Live Stream Poll entity
 * @property {string} _id
 * @property {string} parent_doc_id liveStream doc id
 * @property {string} question the text of the poll question
 * @property {array} answers an array with the answers
 */

/**
 * API path for updating and getting live stream. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_DOC_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_DOC_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/document/live_stream/{{liveStreamId}}`;

/**
 * API path for updating and getting live stream. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_DOC_API_ENDPOINT
 * @private
 */
export const INPUT_SWITCH_DOC_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/input-switch`;

/**
 * API path for updating and getting live stream credentials. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_CREDENTIALS_DOC_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_CREDENTIALS_DOC_API_ENDPOINT =
    `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/credentials`;

/**
 * API path for creating new live stream. Interpolation: `{{eventId}}`.
 * @const {string} LIVE_STREAM_DOC_CREATE_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_DOC_CREATE_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream`;

/**
 * API path for updating live stream. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_DOC_UPDATE_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_DOC_UPDATE_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}`;

/**
 * API path for managing live stream. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{action}}`.
 * @const {string} LIVE_STREAM_ACTION_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_ACTION_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/stream/{{action}}`;

/**
 * API path for managing live stream questions. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_QUESTIONS_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_QUESTIONS_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/questions`;

/**
 * API path for managing live stream polls. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_POLLS_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_POLLS_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/poll`;

/**
 * API path for managing live stream poll executins. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/poll-execution`;

/**
 * API path for managing live stream single poll. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{pollId}}`.
 * @const {string} LIVE_STREAM_POLL_API_BASE_ENDPOINT
 * @private
 */
export const LIVE_STREAM_POLL_API_BASE_ENDPOINT = `${LIVE_STREAM_POLLS_API_ENDPOINT}/{{pollId}}`;

/**
 * API path for starting live stream poll. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{pollId}}`.
 * @const {string} LIVE_STREAM_EXECUTE_POLL_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_EXECUTE_POLL_API_ENDPOINT = `${LIVE_STREAM_POLL_API_BASE_ENDPOINT}/execute`;

/**
 * API path for adjusting live stream poll countdown time. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{pollExecutionId}}`.
 * @const {string} LIVE_STREAM_ADJUST_POLL_COUNTDOWN_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_ADJUST_POLL_COUNTDOWN_API_ENDPOINT = `${LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT}/{{pollExecutionId}}/adjust-countdown`;

/**
 * API path for releasing live stream poll results. Interpolation: `{{eventId}}`, `{{liveStreamId}}`, `{{pollExecutionId}}`.
 * @const {string} LIVE_STREAM_CLOSE_POLL_VOTING_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_CLOSE_POLL_VOTING_API_ENDPOINT = `${LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT}/{{pollExecutionId}}/close-voting`;

/**
 * API endpoint for showing live stream poll results
 * @const {string} LIVE_STREAM_SHOW_POLL_RESULTS_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_SHOW_POLL_RESULTS_API_ENDPOINT = `${LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT}/{{pollExecutionId}}/show-results`;

/**
 * API endpoint for showing live stream poll results
 * @const {string} LIVE_STREAM_HIDE_POLL_RESULTS_API_ENDPOINT
 * @private
 */
export const LIVE_STREAM_HIDE_POLL_RESULTS_API_ENDPOINT = `${LIVE_STREAM_POLL_EXECUTIONS_API_ENDPOINT}/{{pollExecutionId}}/hide-results`;

/**
 * API retrieving reactions. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_ACTION_REACTIONS_ENDPOINT
 * @private
 */
export const LIVE_STREAM_ACTION_REACTIONS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/reactions`;

/**
 * API retrieving frame rate. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_FRAME_RATE_ENDPOINT
 * @private
 */
export const LIVE_STREAM_FRAME_RATE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/frame-rate`;

/**
 * API retrieving frame rate. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_INPUT_SWITCH_ENDPOINT
 * @private
 */
export const LIVE_STREAM_INPUT_SWITCH_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/input-switch`;


/**
 * API retrieving signed URL. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_GET_SIGNED_URL_ENDPOINT
 * @private
 */
export const LIVE_STREAM_GET_SIGNED_URL_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/signed-url`;

/**
 * API endpoint for getting the viewers. Interpolations: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} LIVE_STREAM_VIEWERS_ENDPOINT
 * @private
 */
export const LIVE_STREAM_VIEWERS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/viewers`;

/**
 * API endpoint for live-stream connectivity export
 * @const {string} LIVE_STREAM_CONNECTIVITY_EXPORT_ENDPOINT
 * @private
 */
export const LIVE_STREAM_CONNECTIVITY_EXPORT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/connectivity-export`;

/**
 * API endpoint to release the stage
 * @const {string} LIVE_STREAM_RELEASE_STAGE_ENDPOINT
 * @private
 */
export const LIVE_STREAM_RELEASE_STAGE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/release-stage`;

/**
 * API endpoint to apply targeting to polls in bulk
 * @const {string} LIVE_STREAM_POLLING_BULK_TARGETING_ENDPOINT
 * @private
 */
const LIVE_STREAM_POLLING_BULK_TARGETING_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/bulk-targeting`;

export const UNIFIED_POLLING_VERSION = '1.15.0';

/**
 * Mapping of available stream provider keys
 * @const {Object} provider types
 * @private
 */
export const PROVIDER_TYPES = {
    RTMPS: 'RTMPS',
    THIRD_PARTY: '3rdparty',
    STUDIO: 'studio',
    STREAMLESS: 'none'
};

/**
 * Mapping of available stream session keys
 * @const {Object} session types
 * @private
 */
export const SESSION_TYPES = {
    VIRTUAL: 'REMOTE',
    HYBRID: 'HYBRID',
    IN_PERSON: 'IN_PERSON',
};

/**
 * Mapping of available stream captions services
 * @const {Object} captions services types
 * @private
 */
export const CAPTIONS_SERVICES = {
    AI_MEDIA: 'AI_MEDIA',
    WORDLY: 'WORDLY'
};

/**
 * Mapping of available resolution options
 * @const {Object} RESOLUTION_OPTIONS
 */
export const RESOLUTION_OPTIONS = {
    x1080: '1920x1080',
    x720: '1280x720',
    x540: '960x540',
    x432: '768x432',
    x360: '640x360'
};

export const FULL_RESOLUTION_OPTIONS = [
    {
        label: RESOLUTION_OPTIONS.x1080,
        hint: 'live_sessions.settings.video_section.bandwidth_x1080',
        value: RESOLUTION_OPTIONS.x1080
    },
    {
        label: RESOLUTION_OPTIONS.x720,
        hint: 'live_sessions.settings.video_section.bandwidth_x720',
        value: RESOLUTION_OPTIONS.x720
    },
    {
        label: RESOLUTION_OPTIONS.x540,
        hint: 'live_sessions.settings.video_section.bandwidth_x540',
        value: RESOLUTION_OPTIONS.x540
    },
    {
        label: RESOLUTION_OPTIONS.x432,
        hint: 'live_sessions.settings.video_section.bandwidth_x432',
        value: RESOLUTION_OPTIONS.x432
    },
    {
        label: RESOLUTION_OPTIONS.x360,
        hint: 'live_sessions.settings.video_section.bandwidth_x360',
        value: RESOLUTION_OPTIONS.x360
    }
];

export const WORDLY_URL = 'rtmps://media.wordly.ai/live';

/**
 * @const {String} OUTPUT_RTMPS_ENDPOINTS API path for setting the Studio RTMPS URL. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 */
const OUTPUT_RTMPS_ENDPOINTS = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/output-urls`;

/**
 * Mapping of the different pipeline states
 * @const {Object} pipeline states
 * @private
 */
export const STREAM_STATES = {
    ERROR: 'ERROR',
    RESERVING: 'RESERVING',
    RUNNING: 'RUNNING',
    IDLE: 'IDLE',
    STARTING: 'STARTING',
    STOPPING: 'STOPPING',
    GENERATING_REPLAY: 'GENERATING_REPLAY',
    REPLAY: 'REPLAY',
    RELEASING: 'RELEASING',
    RELEASED: 'RELEASED',
    SCHEDULED: 'SCHEDULED'
};

export const POLLING_COUNTDOWN_FOLLOW = {
    DO_NOTHING: 'do_nothing',
    CLOSE_SHOW_RESULTS: 'close_show_results',
    CLOSE_HIDE_RESULTS: 'close_hide_results'
};

export const POLL_TYPE = {
    MULTIPLE_CHOICE: 'multiple_choice',
    WORD_CLOUD: 'word_cloud',
};

/**
 * @const {String} POLLING_SELECT_ALL_DISPLAYS special value for targeting all live displays from the poll
 */
export const POLLING_SELECT_ALL_DISPLAYS = 'all_displays';

export const POLLING_RESULTS_DELAY = 45;

export const POLLS_COLOURS_COUNT = 3;

export const POLLS_COLOURS_DEFAULTS = [...Array(POLLS_COLOURS_COUNT).keys()]
    .reduce((acc, _, i) => ({
        ...acc,
        [`colour_${i}`]: '#000000',
    }), {});

/**
 * Provides methods necessary for managing live streams
 *
 * @example
 *
 * import LiveStreamService from 'libs/services/live-stream';
 * ...
 * const liveStream = new LiveStreamService();
 */
export default class LiveStreamService extends BaseService {

    /**
     * Returns live stream document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getDoc(eventId, liveStreamId) {

        const liveStreamUrl = LIVE_STREAM_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        const { data: liveStreamDoc } = await this.get(liveStreamUrl);

        // if state is not defined, add the default state
        // the state is not part of the metadata, so it cannot be set at creation time
        if (!this.isThirdParty(liveStreamDoc) && !liveStreamDoc.fp_system) {
            liveStreamDoc.fp_system = {
                state: STREAM_STATES.SCHEDULED
            };
        }

        return liveStreamDoc;
    }

    /**
     * Returns input switch document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getInputSwitchDoc(eventId, liveStreamId) {
        const liveStreamUrl = INPUT_SWITCH_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        const { data } = await this.get(liveStreamUrl);

        return data;
    }

    /**
     * Creates new live stream
     *
     * @param {String} eventId event ID
     * @param {Object} liveStream live stream object
     * @param {'vonage'|'chime'} [webRtcProvider = 'vonage']
     * @returns {Promise<Object>}
     */
    createDoc(eventId, liveStream, webRtcProvider = 'vonage') {
        const url = LIVE_STREAM_DOC_CREATE_API_ENDPOINT
            .replace('{{eventId}}', eventId);

        return this.post(url, liveStream, { params: { webRtcProvider } });
    }

    /**
     * Deletes an existing live stream
     * /!\ This method is NOT called from backstage, but from a package /!\
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    deleteDoc(eventId, liveStreamId) {
        const url = LIVE_STREAM_DOC_UPDATE_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        return this.delete(url);
    }

    /**
     * Updates existing live stream
     *
     * @param {String} eventId event ID
     * @param {Object} liveStream live stream object
     * @returns {Promise<Object>}
     */
    updateDoc(eventId, liveStream) {
        const url = LIVE_STREAM_DOC_UPDATE_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStream._id);

        return this.put(url, omit(liveStream, '_rev', '_attachments'));
    }

    /**
     * Returns live stream credentials document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getCredentials(eventId, liveStreamId) {
        const credentialsUrl = LIVE_STREAM_CREDENTIALS_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data: credentialsDoc } = await this.get(credentialsUrl).catch(err => {
            console.warn('fetching credentials failed', err);
            // it's ok, credentials document might not have been created yet
            return { data: {} };
        });

        return credentialsDoc;
    }

    /**
     * Previews live stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    preview(eventId, liveStreamId) {
        return this.performAction('', eventId, liveStreamId);
    }

    /**
     * Stop live stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    stop(eventId, liveStreamId) {
        return this.performAction('stop', eventId, liveStreamId);
    }

    /**
     * Start live stream broadcasting
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {Object} [args] body to send
     * @returns {Promise<Object>}
     */
    startBroadcast(eventId, liveStreamId, args) {
        return this.performAction('start-broadcast', eventId, liveStreamId, 'post', args);
    }

    /**
     * Stops live stream broadcasting
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    stopBroadcast(eventId, liveStreamId) {
        return this.performAction('stop-broadcast', eventId, liveStreamId, 'post');
    }

    /**
     * Publish or unpublish the live stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {Object} [args] body to send
     * @returns {Promise<Object>}
     */
    togglePublish(eventId, liveStreamId, args) {
        return this.performAction('toggle-publish', eventId, liveStreamId, 'post', args);
    }

    /**
     * Finishes live stream broadcasting
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    finishBroadcast(eventId, liveStreamId) {
        return this.performAction('finish-broadcast', eventId, liveStreamId, 'post');
    }

    /**
     * Cancels the scheduled auto stop action
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    cancelAutoStop(eventId, liveStreamId) {
        return this.performAction('cancel-auto-stop', eventId, liveStreamId, 'post');
    }

    /**
     * Perform an action on live stream
     *
     * @private
     *
     * @param {String} action action to perform (create|start|stop)
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {String} [httpMethod="post"] http method for the request
     * @param {Object} [args={}] body to send
     * @returns {Promise<Object>}
     */
    performAction(action, eventId, liveStreamId, httpMethod = 'post', args = {}) {
        const url = LIVE_STREAM_ACTION_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{action}}', action)
            // remove trailing slash
            .replace(/\/$/, '');

        return this[httpMethod](url, args);
    }

    /**
     * Prepare http endpoint URL by setting its eventId and liveStreamId
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {string} base url for http requests
     */
    _getQuestionsBaseUrl(eventId, liveStreamId) {
        return LIVE_STREAM_QUESTIONS_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
    }

    /**
     * Calls HTTP API endpoint setting live stream question status
     *
     * @param {string} eventId id of the event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question question instance
     * @param {QnaQuestionStatus} status the new status of a question
     * @returns {Promise<void>}
     */
    async _changeQuestionStatus(eventId, liveStreamId, question, status) {
        const url = this._getQuestionsBaseUrl(eventId, liveStreamId);

        await this.post(`${url}/${question.id}/status`, { status });
    }

    /**
     * Modify question text or discussed flag
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question instance of a question
     * @returns {Promise<void>}
     */
    async _modifyQuestion(eventId, liveStreamId, question) {
        const url = this._getQuestionsBaseUrl(eventId, liveStreamId);

        await this.put(`${url}/${question.id}`, question);
    }

    /**
     * Gets the url to download MP4 file for the live stream
     *
     * @param {string} eventId id of an event (workspace)
     * @param {Object} liveStream live stream object
     * @returns {string|null}
     */
    getDownloadUrl(eventId, liveStream) {
        const docId = liveStream._id;
        const fileName = `${docId}.mp4`;
        const hasS3Attachment = liveStream.fp_attachments_s3 && liveStream.fp_attachments_s3[fileName];

        return hasS3Attachment ? this.buildUrl(ASSET_DOWNLOAD_URL, { eventId, docId, fileName }) : null;
    }

    /**
     * Fetch qna questions from server
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {Promise<QnaQuestion[]>} array of unfiltered questions asked for that liveStreamId
     */
    async getQuestions(eventId, liveStreamId) {
        const url = this._getQuestionsBaseUrl(eventId, liveStreamId);
        const { data } = await this.get(url);

        return data;
    }

    /**
     * Fetch qna questions from server
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {Promise<QnaQuestion[]>} array of unfiltered questions asked for that liveStreamId
     */
    async getPaginatedQuestions(eventId, liveStreamId, {
        status = null,
        timestamp = null,
        limit = 100,
        docid = null,
        descending = true,
        sortby = null,
        skip = 0
    } = {}) {
        const url = `${this._getQuestionsBaseUrl(eventId, liveStreamId)}-paginated`;
        const { data } = await this.post(url, {
            status,
            timestamp,
            limit,
            docid,
            descending,
            sortby,
            skip
        });

        return data;
    }

    /**
     * Sets status to `answered`.
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a question we are unhighlighting
     * @param {changeTab} [changeTab] if the question will change to a different state or not
     * @returns {Promise<void>}
     */
    async unhighlightQuestion(eventId, liveStreamId, question, changeTab) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, changeTab ? 'answered' : 'approved');
    }

    /**
     * Set question status to `highlighted`
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a question we are hightlithing
     * @returns {Promise<void>}
     */
    async highlightQuestion(eventId, liveStreamId, question) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, 'highlighted');
    }

    /**
     * Sets question status to `approved`
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a question we are approving
     * @returns {Promise<void>}
     */
    async approveQuestion(eventId, liveStreamId, question) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, 'approved');
    }

    /**
     * Sets question status to to the previous status
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a question we are approving
     * @param {bool} isModerated
     * @returns {Promise<void>}
     */
    async restoreQuestion(eventId, liveStreamId, question, isModerated) {
        const fallbackStatus = isModerated ? 'pending' : 'approved';
        const newStatus = question.prev_status ?? fallbackStatus;

        await this._changeQuestionStatus(
            eventId,
            liveStreamId,
            question,
            newStatus
        );

        return newStatus;
    }

    /**
     * Sets question status to `archived`
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a quenstion we are archiving
     * @returns {Promise<void>}
     */
    async archiveQuestion(eventId, liveStreamId, question) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, 'archived');
    }

    /**
     * Sets question status to `answered`
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a quenstion we are archiving
     * @returns {Promise<void>}
     */
    async moveToAnswered(eventId, liveStreamId, question) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, 'answered');
    }

    /**
     * Sets question status to `approved`
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a quenstion we are archiving
     * @returns {Promise<void>}
     */
    async moveToDiscussion(eventId, liveStreamId, question) {
        await this._changeQuestionStatus(eventId, liveStreamId, question, 'approved');
    }

    /**
     * Updates text of the question
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {QnaQuestion} question an instance of a question we are modifying
     * @returns {Promise<void>}
     */
    async changeQuestionText(eventId, liveStreamId, question) {
        await this._modifyQuestion(eventId, liveStreamId, question);
    }

    /**
     * Add a new question
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {object} questionParams
     * @param {string} questionParams.question new question text
     * @param {boolean} [questionParams.anonymous] whether the question should be posted as anonymous
     * @returns {Promise<void>}
     */
    async addQuestion(eventId, liveStreamId, { question, anonymous }) {
        const url = this._getQuestionsBaseUrl(eventId, liveStreamId);

        await this.post(url, { question, anonymous });
    }

    /**
     * Fetch all polls for a live-stream from server
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {Promise<Poll[]>} array of unfiltered polls asked for that liveStreamId
     */
    async getPolls(eventId, liveStreamId) {
        const url = this._getPollsBaseUrl(eventId, liveStreamId);
        const { data } = await this.get(url);

        return data;
    }

    /**
     * Whether or not upvoting is enabled for questions
     *
     * @param {object} liveStream
     * @returns {boolean}
     */
    isQnaUpvotingEnabled(liveStream) {
        return get(liveStream, 'qna_upvoting_enabled', false);
    }

    /**
     * Reorder polls for a live-stream from server
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {Promise<Poll[]>} array of unfiltered polls asked for that liveStreamId
     */
    async reorderPolls(eventId, liveStreamId, { pollOrder }) {
        const url = `${this._getPollsBaseUrl(eventId, liveStreamId)}/poll-order`;

        const { data } = await this.put(url, { pollOrder });

        return data;
    }

    /**
     * Fetches the poll for a live-stream by id from server
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollId id of the poll
     * @returns {Promise<Poll>} requested poll
     */
    async getPoll(eventId, liveStreamId, pollId) {
        const url = this._getPollBaseUrl(eventId, liveStreamId, pollId);
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Launches poll to app users
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollId id of the poll
     * @returns {Promise<Poll>} requested poll
     */
    async executePoll(eventId, liveStreamId, pollId) {
        const url = this._compilePollUrl(LIVE_STREAM_EXECUTE_POLL_API_ENDPOINT, eventId, liveStreamId, pollId);
        const { data } = await this.post(url, {});
        return data;
    }

    /**
     * Close voting for poll execution
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollExecutionId id of the poll execution
     * @param {boolean} showResults
     *
     * @returns {Promise<void>}
     */
    async closeVoting(eventId, liveStreamId, pollExecutionId, showResults = false) {
        const url = LIVE_STREAM_CLOSE_POLL_VOTING_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{pollExecutionId}}', pollExecutionId);

        await this.post(url, {
            showResults
        });
    }

    /**
     * Show results for poll execution
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollExecutionId id of the poll execution
     *
     * @returns {Promise<void>}
     */
    async showResults(eventId, liveStreamId, pollExecutionId) {
        const url = LIVE_STREAM_SHOW_POLL_RESULTS_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{pollExecutionId}}', pollExecutionId);

        await this.post(url);
    }

    /**
     * Hide results for poll execution
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollExecutionId id of the poll execution
     *
     * @returns {Promise<void>}
     */
    async hideResults(eventId, liveStreamId, pollExecutionId) {
        const url = LIVE_STREAM_HIDE_POLL_RESULTS_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{pollExecutionId}}', pollExecutionId);

        await this.post(url);
    }

    /**
     * Adjust countdown on poll execution
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollExecutionId id of the poll execution
     * @param {number} durationToAdd duration to add to the countdown in seconds
     *
     * @returns {Promise<void>}
     */
    async adjustCountdown(eventId, liveStreamId, pollExecutionId, durationToAdd) {
        const url = LIVE_STREAM_ADJUST_POLL_COUNTDOWN_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{pollExecutionId}}', pollExecutionId);

        await this.put(url, {
            durationToAdd
        });
    }


    /**
     * Prepare http endpoint URL by setting its eventId and liveStreamId
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     *
     * @returns {string} base url for http requests
     * @private
     */
    _getPollsBaseUrl(eventId, liveStreamId) {
        return this._compilePollUrl(LIVE_STREAM_POLLS_API_ENDPOINT, eventId, liveStreamId);
    }

    /**
     * Gets the live-stream's poll endpoint
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollId the id of the poll to manipulate
     *
     * @returns {string} base url for http requests
     * @private
     */
    _getPollBaseUrl(eventId, liveStreamId, pollId) {
        return this._compilePollUrl(LIVE_STREAM_POLL_API_BASE_ENDPOINT, eventId, liveStreamId, pollId);
    }

    /**
     * Given a base url and all its parts, this method compiles
     * the full URL for the poll(s).
     *
     * @param {string} base the base url to interpolate
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} [pollId=''] the id of the poll to manipulate
     *
     * @returns {string} the compiled url
     * @private
     */
    _compilePollUrl(base, eventId, liveStreamId, pollId = '') {
        return base
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId)
            .replace('{{pollId}}', pollId);
    }

    /**
     * Create a new poll
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {object} poll new poll
     * @returns {Promise<Object>}
     */
    createPoll(eventId, liveStreamId, poll) {
        const url = this._getPollsBaseUrl(eventId, liveStreamId);

        return this.post(url, poll);
    }

    /**
     * Updates a poll
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {object} poll the poll to update
     * @returns {Promise<Object>}
     */
    updatePoll(eventId, liveStreamId, poll) {
        const url = this._getPollBaseUrl(eventId, liveStreamId, poll._id);

        return this.put(url, poll);
    }

    /**
     * Returns the last poll execution
     *
     * @param {*} poll poll object
     * @returns {Object|null} executuin object
     */
    getLastPollExecution(poll) {
        if (!poll || !poll.executions || !poll.executions.length) {
            return null;
        }
        return poll.executions[0];
    }

    /**
     * Returns the answers of the last poll execution
     * or the answers of the poll if there is no execution
     *
     * @param {*} poll poll object
     * @returns {Object[]} answers array
     */
    getPollAnswers(poll) {
        const execution = this.getLastPollExecution(poll);

        return execution ? execution.answers : poll.answers;
    }

    /**
     * Returns duplicate of a multiple choice poll
     *
     * @param {Object} poll source poll
     * @returns {Object}
     */
    _makeMultipleChoicePollDuplicate(poll) {
        const answers = this.getPollAnswers(poll)
            .map(answer => pick(answer, ['text', 'isDuplicate', 'correct']));

        return mapKeys(
            {
                ...pick(
                    cloneDeep(poll),
                    [
                        'answer_limit',
                        'question',
                        'poll_type',
                        'has_countdown',
                        'countdown_duration',
                        'countdown_follow_action',
                        'targets',
                        'exceptions',
                        'show_results_live',
                        'live_displays'
                    ]
                ),
                answers
            },
            (_, key) => camelCase(key));

    }

    /**
     * Returns duplicate of a word cloud poll
     *
     * @param {Object} poll source poll
     * @returns {Object}
     */
    _makeWordCloudPollDuplicate(poll) {
        return mapKeys(
            pick(
                cloneDeep(poll),
                [
                    'answer_limit', 'allow_multi_words', 'character_limit', 'question',
                    'poll_type', 'has_countdown', 'countdown_duration', 'countdown_follow_action',
                    'targets', 'exceptions', 'show_results_live', 'live_displays'
                ]
            ), (_, key) => camelCase(key));
    }

    /**
     * Returns a duplicate of a poll based on its type
     *
     * @param {Object} poll source poll
     * @returns {Object}
     */
    makePollDuplicate(poll, question = null) {
        const duplicate = poll.poll_type === 'word_cloud'
            ? this._makeWordCloudPollDuplicate(poll)
            : this._makeMultipleChoicePollDuplicate(poll);

        duplicate.question = question || `[Copy] ${duplicate.question}`;
        return duplicate;
    }

    /**
     * Duplicates a poll
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {object} poll poll to duplicate
     * @returns {Promise<Object>}
     */
    duplicatePoll(eventId, liveStreamId, poll, question) {
        const url = this._getPollsBaseUrl(eventId, liveStreamId);
        return this.post(url, this.makePollDuplicate(poll, question));
    }

    /**
     * Deletes a poll
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} pollId the id of the poll to delete
     * @returns {Promise<Object>}
     */
    deletePoll(eventId, liveStreamId, pollId) {
        const url = this._getPollBaseUrl(eventId, liveStreamId, pollId);
        return this.delete(url);
    }

    /**
     * Get reactions for a given live stream
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getReactions(eventId, liveStreamId) {
        const url = LIVE_STREAM_ACTION_REACTIONS_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.get(url);

        return data;
    }

    /**
     * Get frame rate for a given live stream
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getFrameRate(eventId, liveStreamId) {
        const url = LIVE_STREAM_FRAME_RATE_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.get(url);

        return data?.inputFrameRate;
    }

    /**
     * Get signed URL for live stream
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @param {Number} version live stream document version
     * @returns {Promise<Object>}
     */
    async getSignedUrl(eventId, liveStreamId, version) {
        const getUrl = LIVE_STREAM_GET_SIGNED_URL_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data: { url, cookies, expires } } = await this.get(getUrl, { params: { version } });

        return { url, cookies, expires };
    }

    /**
     * Gets the viewers of the specified live stream
     *
     * @param {String} eventId the ID of the event
     * @param {String} liveStreamId the ID of the live stream
     *
     * @returns {Promise<Object>} an object countaining the viewers
     */
    async getViewers(eventId, liveStreamId) {
        const url = LIVE_STREAM_VIEWERS_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.get(url);

        return data;
    }

    /**
     * Starts live-stream connectivity export task-queue job
     *
     * @param {string} eventId
     * @param {string} liveStreamId
     * @returns {Promise<string>} promise for exportResultId
     */
    async startConnectivityExport(eventId, liveStreamId) {
        const url = LIVE_STREAM_CONNECTIVITY_EXPORT_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url, {});

        return data.exportResultId || '';
    }

    /**
     * Initate the connectivity results download
     *
     * @param {string} eventId
     * @param {string} liveStreamId
     * @param {string} exportResultId
     */
    downloadConnectivityExport(eventId, liveStreamId, exportResultId) {
        const baseUrl = LIVE_STREAM_CONNECTIVITY_EXPORT_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        return `${baseUrl}/${exportResultId}`;
    }

    /**
     * Tells if a live stream is using Studio
     *
     * @param {object} liveStream
     *
     * @returns {boolean}
     */
    isStudio(liveStream) {
        return get(liveStream, 'provider', '') === PROVIDER_TYPES.STUDIO
           || get(liveStream, 'use_studio', false); // backward compatibility
    }

    /**
     * Tells if a live stream is using a third party stream
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    isThirdParty(liveStream) {
        return get(liveStream, 'provider', '') === PROVIDER_TYPES.THIRD_PARTY
            || get(liveStream, 'is_third_party', false); // backward compatibility
    }

    /**
     * Tells if a live stream is using an external RTMPS stream
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    isRtmps(liveStream) {
        return get(liveStream, 'provider', '') === PROVIDER_TYPES.RTMPS
            || (!this.isInPerson(liveStream) && !this.isStudio(liveStream) && !this.isThirdParty(liveStream)); // backward compatibility
    }

    /**
     * Tells if a live stream supports id3 metadata
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    hasId3(liveStream) {
        return this.isRtmps(liveStream) || this.isStudio(liveStream);
    }

    /**
     * Gets the current live stream state
     *
     * @param {object} liveStream the live stream to get the state of
     */
    getState(liveStream) {
        return get(liveStream, 'fp_system.state');
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isScheduled(liveStream) {
        const state = this.getState(liveStream) || STREAM_STATES.SCHEDULED;
        return state === STREAM_STATES.SCHEDULED;
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean} is stream pipeline being reserved
     */
    isReserving(liveStream) {
        return this.getState(liveStream) === STREAM_STATES.RESERVING;
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isBootstrapping(liveStream) {
        const state = this.getState(liveStream);

        // Starting states
        const bootstrapStates = [
            STREAM_STATES.RESERVING,
            STREAM_STATES.IDLE,
            STREAM_STATES.STARTING
        ];

        return bootstrapStates.includes(state) && !this.isFinished(liveStream);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean} is stream running and displaying video
     */
    isRunning(liveStream) {
        if (!liveStream) return false;
        const running = this.getState(liveStream) === STREAM_STATES.RUNNING;

        // TODO: third party should never return isRunning true, they don't have a pipeline so it can be misleading
        return running || (this.isThirdParty(liveStream) && liveStream.url);
    }

    /**
     * @param {Object} liveStream the live stream to check the live time stamp of
     *
     * @returns {Number}
     */
    getIsLiveTimeStamp(liveStream) {
        return get(liveStream, 'fp_system.is_live_timestamp');
    }

    /**
     * @param {Object} liveStream the live stream to check the finished time stamp of
     *
     * @returns {Number}
     */
    getIsFinishedTimeStamp(liveStream) {
        return get(liveStream, 'fp_system.is_finished_timestamp');
    }

    /**
     * @param {Object} liveStream the live stream to check the scheduled start time of
     *
     * @returns {Number}
     */
    startTime(liveStream) {
        return get(liveStream, 'start_time');
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isPreview(liveStream) {
        return this.isRunning(liveStream) && !this.isLive(liveStream);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isLive(liveStream) {
        return Boolean(get(liveStream, 'fp_system.is_live'));
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isStopping(liveStream) {
        const state = this.getState(liveStream);

        return state === STREAM_STATES.STOPPING ||
            (state === STREAM_STATES.RUNNING && this.isFinished(liveStream)) ||
            (state === STREAM_STATES.IDLE && this.isFinished(liveStream)) ||
            (state === STREAM_STATES.RELEASING && !this.isPublished(liveStream));
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isGeneratingReplay(liveStream) {
        return this.isFinished(liveStream) && !this.vodWasGenerated(liveStream);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isFinished(liveStream) {
        return Boolean(get(liveStream, 'fp_system.is_finished'));
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isReleased(liveStream) {
        return this.getState(liveStream) === STREAM_STATES.RELEASED;
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isError(liveStream) {
        if (this.vodComplete(liveStream)) {
            // This is to make it more robust. Maybe an error occurred after creating the VOD, but as long as it was created
            // everything is good as far as the user is concerned.
            return false;
        }

        return this.getState(liveStream) === STREAM_STATES.ERROR;
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    pipelineLoading(liveStream) {
        const state = this.getState(liveStream);

        // IDLE and REPLAY states are handled by the BE as well, so we include them as loading
        const loadingStates = [
            STREAM_STATES.RESERVING,
            STREAM_STATES.IDLE,
            STREAM_STATES.STARTING,
            STREAM_STATES.STOPPING,
            STREAM_STATES.GENERATING_REPLAY,
            STREAM_STATES.REPLAY,
            STREAM_STATES.RELEASING
        ];

        return loadingStates.includes(state);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isResetting(liveStream) {
        if (this.isFinished(liveStream) || this.isError(liveStream)) {
            return false;
        }

        if (liveStream?.fp_system?.to_reset) {
            return true;
        }

        return this.getState(liveStream) === STREAM_STATES.RELEASED;
    }

    /**
     * @deprecated unclear naming, use vodGenerationStarted instead
     */
    isPublished(liveStream) {
        return Boolean(get(liveStream, 'fp_system.is_published'));
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     * @returns {boolean}
     */
    vodGenerationStarted(liveStream) {
        return this.historyHasState(liveStream, STREAM_STATES.GENERATING_REPLAY);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     * @returns {boolean}
     */
    vodWasGenerated(liveStream) {
        return this.historyHasState(liveStream, STREAM_STATES.REPLAY);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isVirtual(liveStream) {
        return SESSION_TYPES.VIRTUAL === this.getSessionType(liveStream);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isHybrid(liveStream) {
        return SESSION_TYPES.HYBRID === this.getSessionType(liveStream);
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    isInPerson(liveStream) {
        return SESSION_TYPES.IN_PERSON === this.getSessionType(liveStream)
            || get(liveStream, 'provider', '') === PROVIDER_TYPES.STREAMLESS;
    }

    /**
     * @param {object} liveStream the live stream to check the state of
     *
     * @returns {boolean}
     */
    vodComplete(liveStream) {
        return this.historyHasState(liveStream, STREAM_STATES.REPLAY);
    }

    /**
     * get the live captions labels
     * @param liveStream
     * @return {*}
     */
    getLiveCaptionsLabels(liveStream) {
        return get(liveStream, 'live_captions_labels', {});
    }

    /**
     * get the rtmps credentials
     * @param liveStream
     * @return {*}
     */
    getRtmpsInputs(liveStream) {
        return get(liveStream, 'fp_system.inputs');
    }

    /**
     * Checks whether the live stream state history contains a given state
     *
     * @param {object} liveStream
     * @param {string} state
     *
     * @returns {boolean}
     */
    historyHasState(liveStream, state) {
        const history = get(liveStream, 'fp_system.state_history') || [];
        return history.some(historyItem => historyItem.state === state);
    }

    /**
     * Tries to extract the liveStream ID from the location URL.
     *
     * @param {String} [href=window.location.href] the url from which extract the liveStream ID
     *
     * @returns {String} the current liveStream ID or null.
     */
    getCurrentLiveStreamIdFromUrl(href = window.location.href) {

        const parts = href.split('?')[0].split('/');
        // 0: protocol
        // 1: empty
        // 2: domain
        const startRoute = parts[3];
        const liveStreamId = parts[5];

        if ((startRoute === STUDIO_EXPRESS_BASE_PATHLET || startRoute === 'studio' || startRoute === 'live-display')
            && !isEmpty(liveStreamId))
        {
            return liveStreamId;
        }
        return null;
    }

    /**
     * Switches the input to the video with specified id
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string} documentId the id of the video document
     * @returns {Promise<void>}
     */
    async inputSwitchToMp4(eventId, liveStreamId, documentId) {

        const url = `${LIVE_STREAM_INPUT_SWITCH_ENDPOINT}/mp4`
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url, { documentId });

        return data;
    }

    /**
     * Switches the input to the video with specified id
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @returns {Promise<void>}
     */
    async inputSwitchToRtmps(eventId, liveStreamId) {

        const url = `${LIVE_STREAM_INPUT_SWITCH_ENDPOINT}/rtmps`
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url);

        return data;
    }

    /**
     * Persists input switch videos list in the live stream document
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} liveStreamId id of a live_stream document
     * @param {string[]} videoList input switch videos ids list
     * @returns {Promise<void>}
     */
    async inputSwitchSyncVideos(eventId, liveStreamId, videoList) {

        const url = `${LIVE_STREAM_INPUT_SWITCH_ENDPOINT}/sync-videos`
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        // TEMP: strip expires in videos if present
        for (const video of videoList) {
            if (video.url) {
                video.url = video.url.replace(/\?expires=\d+/, '');
            }
        }
        const { data } = await this.put(url, videoList);

        return data;
    }

    /**
     * Tells the session type of a live stream
     *
     * @param {Object} liveStream
     *
     * @returns {string}
     */
    getSessionType(liveStream) {
        return get(liveStream, 'session_type', SESSION_TYPES.VIRTUAL);
    }

    /**
     * Get the stream url from the live stream document
     *
     * @param {Object} liveStreamDoc
     * @returns {string}
     */
    getStreamUrl(liveStreamDoc) {
        return this.isThirdParty(liveStreamDoc)
            ? get(liveStreamDoc, 'url', '')
            : get(liveStreamDoc, 'fp_system.url', '');
    }

    // getters

    // max character limit for word cloud answers
    get maxCharacterLimit() {
        return 30;
    }

    get maxWordCloudAnswerLimit() {
        return 20;
    }

    /**
     * Given a list of packages, this method will check if the word cloud is supported or not
     *
     * @param {Array} packages the list of packages installed, as returned by $services.market.getInstalledPackages()
     *
     * @return {Boolean} whether word cloud is supported or not
     */
    isUnifiedPolling(packages) {
        const liveStreamLibVersion = get(packages, 'live-streams', '-1');

        try {
            return gte(liveStreamLibVersion, UNIFIED_POLLING_VERSION);
        } catch (error) {
            console.error('[isUnifiedPolling] An error occurred:', error);
        }

        return false;
    }

    async releaseStage(eventId, liveStreamId) {
        const url = LIVE_STREAM_RELEASE_STAGE_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const { data } = await this.post(url);

        return data;
    }

    /**
     * Updates credentials urls for studio rtmps output or multicast
     *
     * @param {string} eventId
     * @param {String} liveStreamId
     * @param {String} urls
     * @returns {Promise<void>}
     */
    async setRtmpsUrls(eventId, liveStreamId, urls) {
        const url = OUTPUT_RTMPS_ENDPOINTS
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);
        await this.post(url, urls, { withCredentials: true });
    }

    /**
     * Applies targeting to a list of polls
     *
     * @param {string} eventId
     * @param {String} liveStreamId
     * @param {Object[]} polls
     * @param {{ targets: Object[], exceptions: object[] }} targeting
     * @returns {Promise<void>}
     */
    async applyTargetingToPollsInBulk(eventId, liveStreamId, polls, targeting) {
        const url = LIVE_STREAM_POLLING_BULK_TARGETING_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{liveStreamId}}', liveStreamId);

        const targetingByPollId = polls.reduce((acc, curr) => {
            acc[curr._id] = targeting;
            return acc;
        }, {});

        await this.post(url, { targeting: targetingByPollId });
    }

    /**
     * Checks whether a list of polls share the same targeting config. If less than two elements, returns true
     *
     * @param {object[]} polls
     * @returns {boolean}
     */
    haveSameTargeting(polls) {
        if (polls.length < 2) {
            return true;
        }

        const equalRules = ((rules1, rules2) => {
            if (rules1.length !== rules2.length) {
                return false;
            }

            const sortedRules1 = rules1.map(rule => JSON.stringify(rule)).sort();
            const sortedRules2 = rules2.map(rule => JSON.stringify(rule)).sort();
            return isEqual(sortedRules1, sortedRules2);
        });

        let previousPoll = polls[0];

        for (let i = 1; i < polls.length; i++) {
            const currentPoll = polls[i];
            if (!equalRules(previousPoll.targets, currentPoll.targets) || !equalRules(previousPoll.exceptions, currentPoll.exceptions)) {
                return false;
            }
            previousPoll = currentPoll;
        }

        return true;
    }
}
